import { ReactNode } from 'react';

import { Footer } from '@/components';

import styles from './layout.module.scss';

type Props = {
  children: ReactNode;
  gradient?: boolean;
  rounded?: boolean;
  widthCapped?: boolean;
  footer?: boolean;
  sx?: {
    wrapper?: string;
    contentWrapper?: string;
    container?: string;
    innerContainer?: string;
  };
};

export default function Layout({
  children,
  gradient,
  rounded,
  widthCapped,
  footer = true,
  sx,
}: Props) {
  return (
    <div
      data-gradient={gradient}
      className={`${styles.wrapper} ${sx?.wrapper ?? ''}`}
    >
      <div className={`${styles.contentWrapper} ${sx?.contentWrapper ?? ''}`}>
        <div
          data-width-capped={widthCapped}
          data-rounded={rounded}
          className={`${styles.container} ${sx?.container ?? ''}`}
        >
          <div
            className={`${styles.innerContainer} ${sx?.innerContainer ?? ''}`}
          >
            {children}
          </div>
          {footer && <Footer />}
        </div>
      </div>
    </div>
  );
}
