import { FC, SVGProps } from 'react';

import { Clear } from '@mui/icons-material';
import { Container, IconButton, Stack, Typography } from '@mui/material';

interface Arguments {
  title: string;
  icon?: FC<SVGProps<SVGSVGElement>>;
  price?: string;
  description?: string;
  onClose?: () => void;
  sx?: any;
}

export default function SectionTitle({
  title,
  icon: Icon,
  price,
  description,
  onClose,
  sx,
}: Arguments) {
  return (
    <Container sx={{ paddingY: '16px' }}>
      <Stack gap='8px'>
        <Stack
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
          gap='8px'
          alignSelf='stretch'
        >
          {Icon && <Icon />}
          <Typography variant={sx?.title ?? 'title_md'} flex='1 0 0'>
            {title}
          </Typography>
          {onClose && (
            <IconButton
              onClick={onClose}
              sx={{ margin: '-5px !important', padding: '5px !important' }}
              size='small'
            >
              <Clear />
            </IconButton>
          )}
        </Stack>
        {price && <Typography variant='title_md'>{price}</Typography>}
        {description && (
          <Typography
            variant='caption'
            color='var(--onSurfaceColors-mediumEmphasis)'
          >
            {description}
          </Typography>
        )}
      </Stack>
    </Container>
  );
}
