import { SVGProps } from 'react';

export default function QlubLogo(props: SVGProps<SVGSVGElement>) {
  const { color = '#7D00D4', ...rest } = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='14'
      viewBox='0 0 40 14'
      fill='none'
      {...rest}
    >
      <g clipPath='url(#clip0_2287_47442)'>
        <path
          d='M34.0608 4.66775C34.3371 4.66775 34.561 4.45531 34.561 4.19325C34.561 3.93119 34.3371 3.71875 34.0608 3.71875C33.7845 3.71875 33.5605 3.93119 33.5605 4.19325C33.5605 4.45531 33.7845 4.66775 34.0608 4.66775Z'
          fill={color}
        />
        <path
          d='M32.7121 5.97244C32.9884 5.97244 33.2124 5.76 33.2124 5.49794C33.2124 5.23588 32.9884 5.02344 32.7121 5.02344C32.4359 5.02344 32.2119 5.23588 32.2119 5.49794C32.2119 5.76 32.4359 5.97244 32.7121 5.97244Z'
          fill={color}
        />
        <path
          d='M32.7121 4.66775C32.9884 4.66775 33.2124 4.45531 33.2124 4.19325C33.2124 3.93119 32.9884 3.71875 32.7121 3.71875C32.4359 3.71875 32.2119 3.93119 32.2119 4.19325C32.2119 4.45531 32.4359 4.66775 32.7121 4.66775Z'
          fill={color}
        />
        <path
          d='M37.2463 4.68435C37.5226 4.68435 37.7466 4.47191 37.7466 4.20985C37.7466 3.94779 37.5226 3.73535 37.2463 3.73535C36.9701 3.73535 36.7461 3.94779 36.7461 4.20985C36.7461 4.47191 36.9701 4.68435 37.2463 4.68435Z'
          fill={color}
        />
        <path
          d='M38.595 5.98904C38.8712 5.98904 39.0952 5.7766 39.0952 5.51454C39.0952 5.25248 38.8712 5.04004 38.595 5.04004C38.3187 5.04004 38.0947 5.25248 38.0947 5.51454C38.0947 5.7766 38.3187 5.98904 38.595 5.98904Z'
          fill={color}
        />
        <path
          d='M38.595 4.68435C38.8712 4.68435 39.0952 4.47191 39.0952 4.20985C39.0952 3.94779 38.8712 3.73535 38.595 3.73535C38.3187 3.73535 38.0947 3.94779 38.0947 4.20985C38.0947 4.47191 38.3187 4.68435 38.595 4.68435Z'
          fill={color}
        />
        <path
          d='M34.0911 10.6951C34.3673 10.6951 34.5913 10.4827 34.5913 10.2206C34.5913 9.95853 34.3673 9.74609 34.0911 9.74609C33.8148 9.74609 33.5908 9.95853 33.5908 10.2206C33.5908 10.4827 33.8148 10.6951 34.0911 10.6951Z'
          fill={color}
        />
        <path
          d='M32.7424 9.39041C33.0187 9.39041 33.2427 9.17797 33.2427 8.91591C33.2427 8.65385 33.0187 8.44141 32.7424 8.44141C32.4661 8.44141 32.2422 8.65385 32.2422 8.91591C32.2422 9.17797 32.4661 9.39041 32.7424 9.39041Z'
          fill={color}
        />
        <path
          d='M32.7424 10.6951C33.0187 10.6951 33.2427 10.4827 33.2427 10.2206C33.2427 9.95853 33.0187 9.74609 32.7424 9.74609C32.4661 9.74609 32.2422 9.95853 32.2422 10.2206C32.2422 10.4827 32.4661 10.6951 32.7424 10.6951Z'
          fill={color}
        />
        <path
          d='M37.2766 10.6785C37.5529 10.6785 37.7768 10.4661 37.7768 10.204C37.7768 9.94193 37.5529 9.72949 37.2766 9.72949C37.0003 9.72949 36.7764 9.94193 36.7764 10.204C36.7764 10.4661 37.0003 10.6785 37.2766 10.6785Z'
          fill={color}
        />
        <path
          d='M38.6252 9.37381C38.9015 9.37381 39.1255 9.16137 39.1255 8.89931C39.1255 8.63725 38.9015 8.4248 38.6252 8.4248C38.349 8.4248 38.125 8.63725 38.125 8.89931C38.125 9.16137 38.349 9.37381 38.6252 9.37381Z'
          fill={color}
        />
        <path
          d='M38.6252 10.6785C38.9015 10.6785 39.1255 10.4661 39.1255 10.204C39.1255 9.94193 38.9015 9.72949 38.6252 9.72949C38.349 9.72949 38.125 9.94193 38.125 10.204C38.125 10.4661 38.349 10.6785 38.6252 10.6785Z'
          fill={color}
        />
        <path
          d='M7.96948 3.77559V13.2267H5.52534V9.82317C5.32425 10.1235 5.04212 10.3622 4.68095 10.5406C4.31878 10.7189 3.9236 10.808 3.4934 10.808C2.90712 10.808 2.38788 10.6625 1.93467 10.3725C1.48146 10.0825 1.12929 9.6674 0.877176 9.12731C0.626059 8.58824 0.5 7.96206 0.5 7.24878C0.5 6.53651 0.62806 5.90316 0.884179 5.34975C1.1403 4.79633 1.49546 4.36795 1.94868 4.06255C2.40189 3.75817 2.91713 3.60547 3.4934 3.60547C3.9416 3.60547 4.3518 3.69975 4.72197 3.88628C5.09314 4.07382 5.37427 4.33208 5.56636 4.66003V3.77354H7.96948V3.77559ZM5.2232 8.49396C5.45231 8.1988 5.56636 7.77452 5.56636 7.2211C5.56636 6.65847 5.45131 6.22701 5.2232 5.92673C4.99409 5.62645 4.66494 5.47683 4.23474 5.47683C3.82255 5.47683 3.5004 5.63158 3.26629 5.94108C3.03218 6.25058 2.91613 6.68614 2.91613 7.24878C2.91613 7.79297 3.03018 8.21008 3.25929 8.5001C3.48839 8.79116 3.81254 8.93566 4.23374 8.93566C4.66494 8.93669 4.99409 8.78911 5.2232 8.49396Z'
          fill={color}
        />
        <path
          d='M20.9716 3.77539V10.6951H18.5965V9.7666C18.3854 10.1038 18.1183 10.362 17.7932 10.5404C17.468 10.7187 17.0948 10.8078 16.6736 10.8078C15.8042 10.8078 15.1589 10.5619 14.7377 10.0689C14.3165 9.57701 14.1064 8.82888 14.1064 7.82556V3.77539H16.5506V7.88192C16.5506 8.55729 16.8337 8.89447 17.402 8.89447C17.7311 8.89447 18.0013 8.77251 18.2124 8.5286C18.4225 8.28469 18.5285 7.96596 18.5285 7.57242V3.77539H20.9716Z'
          fill={color}
        />
        <path
          d='M28.4262 4.04239C28.8745 4.33344 29.2246 4.7485 29.4767 5.28757C29.7279 5.82663 29.8539 6.45281 29.8539 7.16508C29.8539 7.87734 29.7279 8.51069 29.4767 9.06411C29.2246 9.61752 28.8725 10.0459 28.4192 10.3513C27.966 10.6557 27.4508 10.8084 26.8745 10.8084C26.4263 10.8084 26.0161 10.7141 25.6459 10.5276C25.2748 10.34 24.9936 10.0828 24.8016 9.75382V10.6957H22.3984V0.779297H24.8426V4.59068C25.0437 4.2904 25.3248 4.05161 25.687 3.87329C26.0481 3.69497 26.4443 3.60581 26.8745 3.60581C27.4608 3.60581 27.978 3.75133 28.4262 4.04239ZM27.0946 8.47892C27.3237 8.17454 27.4378 7.73591 27.4378 7.16405C27.4378 6.62089 27.3257 6.20275 27.1016 5.91272C26.8775 5.62269 26.5544 5.47716 26.1342 5.47716C25.704 5.47716 25.3738 5.62474 25.1457 5.91989C24.9166 6.21505 24.8026 6.63933 24.8026 7.19275C24.8026 7.75538 24.9166 8.18684 25.1457 8.48712C25.3748 8.7874 25.7 8.93703 26.1202 8.93703C26.5414 8.936 26.8665 8.78432 27.0946 8.47892Z'
          fill={color}
        />
        <path
          d='M9.12695 0.773438H11.7212V7.96269C11.7212 7.96269 11.7072 8.59707 12.3264 8.61244C12.9457 8.62781 13.2059 8.61244 13.2059 8.61244V10.5945C12.7887 10.6642 12.3605 10.6949 11.9383 10.6949C10.6837 10.6949 9.238 10.0749 9.12795 8.64216C9.12695 7.38673 9.12695 0.773438 9.12695 0.773438Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_2287_47442'>
          <rect
            width='38.625'
            height='12.4528'
            fill='white'
            transform='translate(0.5 0.773438)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
