import { SVGProps } from 'react';

export default function QlubQR(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height={45}
      fill='none'
      viewBox='127.62 11.88 27.8 27.9'
      {...props}
    >
      <g fill={props.color}>
        <path d='M135.057 15.671c1.111 0 2.012-.85 2.012-1.898s-.901-1.898-2.012-1.898-2.012.85-2.012 1.898.901 1.898 2.012 1.898ZM129.634 20.89c1.111 0 2.011-.85 2.011-1.898 0-1.049-.9-1.898-2.011-1.898-1.111 0-2.012.85-2.012 1.898s.901 1.898 2.012 1.898ZM129.634 15.671c1.111 0 2.011-.85 2.011-1.898s-.9-1.898-2.011-1.898c-1.111 0-2.012.85-2.012 1.898s.901 1.898 2.012 1.898ZM147.867 15.737c1.111 0 2.012-.85 2.012-1.898s-.901-1.898-2.012-1.898-2.012.85-2.012 1.898c0 1.049.901 1.898 2.012 1.898ZM153.29 20.954c1.111 0 2.012-.85 2.012-1.898s-.901-1.898-2.012-1.898-2.011.85-2.011 1.898.9 1.898 2.011 1.898ZM153.29 15.737c1.111 0 2.012-.85 2.012-1.898s-.901-1.898-2.012-1.898-2.011.85-2.011 1.898c0 1.049.9 1.898 2.011 1.898ZM135.178 39.778c1.111 0 2.011-.85 2.011-1.898s-.9-1.898-2.011-1.898c-1.111 0-2.012.85-2.012 1.898 0 1.049.901 1.898 2.012 1.898ZM129.754 34.562c1.111 0 2.012-.85 2.012-1.898 0-1.049-.901-1.898-2.012-1.898s-2.011.85-2.011 1.898.9 1.898 2.011 1.898ZM129.754 39.778c1.111 0 2.012-.85 2.012-1.898s-.901-1.898-2.012-1.898-2.011.85-2.011 1.898c0 1.049.9 1.898 2.011 1.898ZM147.988 39.714c1.111 0 2.011-.85 2.011-1.898s-.9-1.898-2.011-1.898c-1.111 0-2.012.85-2.012 1.898s.901 1.898 2.012 1.898ZM153.412 34.495c1.111 0 2.011-.85 2.011-1.898s-.9-1.898-2.011-1.898c-1.111 0-2.012.85-2.012 1.898s.901 1.898 2.012 1.898ZM153.412 39.714c1.111 0 2.011-.85 2.011-1.898s-.9-1.898-2.011-1.898c-1.111 0-2.012.85-2.012 1.898s.901 1.898 2.012 1.898Z' />
      </g>
    </svg>
  );
}
