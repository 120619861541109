import { memo, useCallback } from 'react';

import { CancelRounded, CheckCircleRounded } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

type Props = {
  status?: 'error' | 'success';
  text: string;
  sx?: {
    root?: string;
    icon?: string;
    text?: string;
  };
};

const StatusHeader = memo(function StatusHeader({ status, text, sx }: Props) {
  const getStatusIcon = useCallback(
    function getStatusIcon() {
      switch (status) {
        case 'error':
          return (
            <CancelRounded
              className={sx?.icon}
              color='error'
              sx={{ fontSize: '40px' }}
            />
          );
        default:
          return (
            <CheckCircleRounded
              className={sx?.icon}
              color='success'
              sx={{ fontSize: '40px' }}
            />
          );
      }
    },
    [status, sx?.icon],
  );

  return (
    <Stack gap='8px' alignItems='center' className={sx?.root}>
      {getStatusIcon()}
      <Typography className={sx?.text} variant='title_lg' align='center'>
        {text}
      </Typography>
    </Stack>
  );
});

export default StatusHeader;
