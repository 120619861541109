import { useEffect, useState } from 'react';

import Head from 'next/head';

import { PageLoader } from '@/components';
import { usePaymentLink, useRestaurant } from '@/hooks';

function Content() {
  const { isFetched: isRestaurantLoading } = useRestaurant({
    shouldUpdateLoader: true,
  });
  const { isFetched: isLinkLoading } = usePaymentLink({
    shouldUpdateStatus: true,
    shouldUpdateLoader: true,
  });

  const [progress, setProgress] = useState(10);

  useEffect(() => {
    const updateProgress = () => {
      if (isRestaurantLoading && isLinkLoading) {
        setProgress(100);
      } else if (isRestaurantLoading || isLinkLoading) {
        setProgress(prev => prev + 30);
      }
    };

    if (progress === 10) {
      setProgress(20);
    } else {
      const timeoutId = setTimeout(updateProgress, 200);
      return () => clearTimeout(timeoutId);
    }
  }, [isLinkLoading, isRestaurantLoading]);

  return (
    <>
      <Head>
        <title>Loading | Qlub Pay</title>
      </Head>
      <PageLoader progress={progress} />
    </>
  );
}

export default Content;
