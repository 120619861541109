import { Drawer, DrawerProps } from '@mui/material';

import DrawerContent from './content';

export default function YourDetails({
  proceedPayment,
  dsi,
  ...props
}: DrawerProps & { proceedPayment: () => void; dsi: string }) {
  return (
    <Drawer anchor='bottom' {...props}>
      <DrawerContent
        onClose={props.onClose}
        proceedPayment={proceedPayment}
        dsi={dsi}
      />
    </Drawer>
  );
}
