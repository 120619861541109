import { memo, useCallback } from 'react';

import { useTranslation } from 'next-export-i18n';

import { InlineToast } from '@/components';
import { useStore } from '@/store';
import { CurrentStatuses } from '@/types';

function getVariant(arg: string) {
  if (arg === CurrentStatuses.EXPIRED || arg === CurrentStatuses.DISCARDED)
    return 'warning';
  else if (arg === CurrentStatuses.PAID) return 'success';
  else return 'info';
}

const Status = memo(function Status() {
  const { t } = useTranslation();
  const currentStatus = useStore(state => state.currentStatus);

  const getMessage = useCallback(() => {
    if (currentStatus === 'expired') return 'the-link-has-expired';
    else if (currentStatus === CurrentStatuses.PAID) return 'paid-in-full';
    else if (currentStatus === CurrentStatuses.DISCARDED)
      return 'the-link-has-been-discarded';
    else return '';
  }, [currentStatus]);

  if (currentStatus === CurrentStatuses.CREATED) return null;

  return (
    <InlineToast
      variant={getVariant(currentStatus)}
      message={t(getMessage())}
    />
  );
});

export default Status;
