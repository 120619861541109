import { FocusEvent, MouseEvent, memo, useRef } from 'react';

import { isEqual } from 'lodash';
import { useTranslation } from 'next-export-i18n';

import { useQlubRouter } from '@clubpay/customer-common-module/src/hook/router';
import {
  PhoneInput as CustomPhoneInput,
  IPhoneInputProps,
} from '@qlub-dev/qlub-kit';
import { ICountryData } from '@qlub-dev/qlub-kit/dist/components/PhoneInput/types';

import styles from './styles.module.scss';

const PhoneInput: React.FC<IPhoneInputProps> = ({
  country,
  value,
  onChange,
  isValid,
  ...props
}: IPhoneInputProps) => {
  const { t } = useTranslation();
  const { lang } = useQlubRouter();
  const clickedRef = useRef(false);

  function handleOnClick(
    e: MouseEvent<HTMLInputElement>,
    data: ICountryData | Record<string, unknown>,
  ) {
    props.onClick?.(e, data);
    if (!clickedRef.current) {
      const length = e.currentTarget.value.length;
      e.currentTarget.setSelectionRange(length, length);

      clickedRef.current = true;
    }
  }

  function handleOnBlur(
    e: FocusEvent<HTMLInputElement>,
    data: ICountryData | Record<string, unknown>,
  ) {
    props.onBlur?.(e, data);
    clickedRef.current = false;
  }

  return (
    <CustomPhoneInput
      country={country}
      placeholder={t('mobile-number')}
      masks={{ ae: '.. ... ....' }}
      enableSearch
      value={value}
      onChange={onChange}
      containerClass={styles.phoneInput}
      inputClass={styles.input}
      drawerTitle={t('select-your-country')}
      searchInputPlaceHolder={t('search-for-countries')}
      lang={lang}
      isValid={isValid}
      onClick={handleOnClick}
      onBlur={handleOnBlur}
      {...props}
    />
  );
};

export default memo(PhoneInput, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
);
