import { useRef, useState } from 'react';

import { isEqual } from 'lodash';
import { useTranslation } from 'next-export-i18n';
import { useSnackbar } from 'notistack';

import { YourDetailsDrawer } from '@/components';
import { usePaymentLink, useRestaurant, useSaveCustomerData } from '@/hooks';
import { getStatus } from '@/services';
import { useStore } from '@/store';
import { LinkStatus, PaymentElement, TipModeEnum } from '@/types';
import { calculateTip, calculateTotalAmounts } from '@/utils';
import { useQlubRouter } from '@clubpay/customer-common-module/src/hook/router';
import {
  getDiningSession,
  getSession,
} from '@clubpay/customer-common-module/src/service/session/dsi';
import {
  ConsumerTypes,
  PaymentPortal,
  getPaymentKitProps,
} from '@clubpay/payment-kit';
import { Container, Typography } from '@mui/material';

import styles from './styles.module.scss';

function PaymentOptions() {
  const { t } = useTranslation();
  const { url, routerPush, lang } = useQlubRouter();
  const id = getSession();
  const { data: restaurant } = useRestaurant();
  const { data: payment, refetch: fetchLink } = usePaymentLink({
    shouldUpdateStatus: true,
  });
  const { enqueueSnackbar } = useSnackbar();
  const selectedTip = useStore(state => state.selectedTip);
  const customTip = useStore(state => state.customTip);
  const isYourDetailsOpen = useStore(state => state.isYourDetailsOpen);
  const setIsYourDetailsOpen = useStore(state => state.setIsYourDetailsOpen);
  const setInitialLinkData = useStore(state => state.setInitialLinkData);
  const initialLink = useStore(state => state.initialLink);
  const diningSessionPayload = getDiningSession(url);
  const [ref, setRef] = useState<string | undefined>();
  const payHandle = useRef<any>(null);

  useSaveCustomerData({ ref });

  const dsi = diningSessionPayload?.id || '';

  const tip = calculateTip({
    remaining: payment?.bill.remaining,
    selectedTip,
    customTip,
    tipMode: restaurant?.config.paymentLinkTipMode ?? TipModeEnum.Default,
    isTipEnabled: restaurant?.config.paymentLinkTipEnabled,
  });
  const amount = calculateTotalAmounts({
    remaining: payment?.bill.remaining,
    selectedTip,
    customTip,
    tipMode: restaurant?.config.paymentLinkTipMode ?? TipModeEnum.Default,
    currencyPrecision: payment?.currencyPrecision,
    isTipEnabled: restaurant?.config.paymentLinkTipEnabled,
  }).total;
  const shouldPaymentInterrupt =
    restaurant?.config.paymentLinkCollectCustomerEmail === true ||
    restaurant?.config.paymentLinkCollectCustomerMobile === true ||
    payment?.isMultiUse;

  async function onBeforePayment() {
    try {
      const { data } = await fetchLink();
      if (data) {
        const updateLinkData = {
          billAmount: data.bill.billAmount!,
          reference: data.reference || '',
          description: data.description || '',
        };
        if (data.status === LinkStatus.DISCARDED)
          enqueueSnackbar(t('the-link-has-been-discarded'), {
            variant: 'error',
            autoHideDuration: 5000,
          });
        else if (
          initialLink &&
          initialLink.sequentialID === data.sequentialID &&
          !isEqual(initialLink.data, updateLinkData)
        ) {
          enqueueSnackbar(
            t(
              'details-of-the-payment-link-was-modified-please-review-and-proceed',
            ),
            {
              variant: 'info',
              autoHideDuration: 5000,
            },
          );
          setInitialLinkData({
            sequentialID: data.sequentialID,
            data: updateLinkData,
          });
        } else if (Number(data.bill.remaining) <= 0)
          enqueueSnackbar(t('this-bill-was-fully-paid'), {
            variant: 'warning',
            autoHideDuration: 5000,
          });
        else if (data.expired || data.expiration < Date.now()) return;
        else return Promise.resolve(true);
      }
      return Promise.reject('FORCE_FAILED');
    } catch (e) {
      if (e !== 'FORCE_FAILED')
        enqueueSnackbar(t('something-went-wrong'), {
          variant: 'error',
          autoHideDuration: 5000,
        });
      return Promise.reject('FORCE_FAILED');
    }
  }

  const onPaymentPending = async (_gatewayId: any, payload: any) => {
    const ref = payload.refId;
    if (payload.paymentElement === PaymentElement.stcpay) {
      routerPush('/qr/[cc]/[slug]/[id]/[f1]/[f2]/[hash]/invoice/pending', {
        ref,
      });
    }
    if (ref) {
      await getStatus({
        id,
        diningSessionID: dsi,
        reference: ref,
      }).then(
        res => {
          if (res && res.record.accepted) return Promise.resolve(true);
          return Promise.resolve(false);
        },
        () => Promise.resolve(false),
      );
    } else return Promise.resolve(false);
  };

  const onPaymentSuccess = async (_gatewayId: any, payload: any) => {
    const ref = payload.refId;
    if (ref) {
      setRef(ref);
      await getStatus({
        id,
        diningSessionID: dsi,
        reference: ref,
      }).then(
        res => {
          if (res && res.record.accepted)
            routerPush(
              '/qr/[cc]/[slug]/[id]/[f1]/[f2]/[hash]/invoice/success',
              {
                ref: payload.refId,
              },
            );
        },
        () => {
          enqueueSnackbar(t('something-went-wrong'), {
            variant: 'error',
            autoHideDuration: 5000,
          });
        },
      );
    }
  };

  function onPaymentFailure(_id: any, payload: any) {
    if (!(typeof payload === 'string' && payload === 'FORCE_FAILED')) {
      if (payload?.error === 'PAYMENT_AMOUNT_IS_ZERO')
        enqueueSnackbar(t('this-bill-was-fully-paid'), {
          variant: 'error',
          autoHideDuration: 5000,
        });
      else routerPush('/qr/[cc]/[slug]/[id]/[f1]/[f2]/[hash]/invoice/fail');
    }
  }

  function onPaymentMethodSelect(data: any) {
    payHandle.current = data.fn;
  }

  return (
    <>
      <Container className={styles.container}>
        <Typography variant='title_md'>{t('payment-method')}</Typography>
        <div className={styles.paymentWrapper}>
          <PaymentPortal
            {...getPaymentKitProps(restaurant?.paymentGatewayList.itemsList, {
              amount: Number(amount || 0),
              totalAmount: Number(amount || 0),
              vendor: restaurant,
              hasSplitPayment: false,
              enableCardPayment: true,
              diningSessionID: diningSessionPayload?.id || '',
              currencyPrecision: payment?.currencyPrecision || 2,
              tip,
              lang,
              remainingAmount: Number(amount || 0),
              consumer: ConsumerTypes.PAYMENT_LINK,
              extraConfig: {
                disableToast: true,
              },
              events: {
                onBeforePayment,
                onPaymentPending,
                onPaymentSuccess,
                onPaymentFailure,
                ...(shouldPaymentInterrupt
                  ? {
                      onPrePayment: (fn: any) => {
                        payHandle.current = fn;
                        setIsYourDetailsOpen(true);
                      },
                    }
                  : {}),
                onPaymentMethodSelect,
              },
            })}
          />
        </div>
      </Container>
      <YourDetailsDrawer
        open={isYourDetailsOpen}
        onClose={() => setIsYourDetailsOpen(false)}
        proceedPayment={payHandle.current}
        dsi={dsi}
      />
    </>
  );
}

export default PaymentOptions;
