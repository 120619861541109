import { memo } from 'react';

import { Container, Typography } from '@mui/material';

import styles from './styles.module.scss';

type Variants = 'warning' | 'success' | 'info';

type Props = {
  message: string;
  variant: Variants;
};

function getVariantClass(arg: Variants) {
  if (arg === 'warning') return styles.warning;
  else if (arg === 'success') return styles.success;
  else return styles.info;
}

const InlineToast = memo(function InlineToast({ message, variant }: Props) {
  return (
    <Container className={`${styles.container} ${getVariantClass(variant)}`}>
      <Typography variant='caption'>{message}</Typography>
    </Container>
  );
});

export default InlineToast;
