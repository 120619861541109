import { SVGProps } from 'react';

export default function QlubText(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height={45}
      fill='none'
      viewBox='0.1 0.09 118.04 49.81'
      {...props}
    >
      <g fill={props.color}>
        <path d='M30.134 12.1v37.805h-9.829V36.29c-.808 1.2-1.943 2.156-3.395 2.87-1.457.713-3.046 1.07-4.776 1.07-2.358 0-4.446-.583-6.268-1.743-1.823-1.16-3.24-2.82-4.253-4.98C.603 31.35.096 28.845.096 25.992c0-2.849.515-5.382 1.545-7.596 1.03-2.214 2.458-3.927 4.28-5.149 1.823-1.217 3.895-1.828 6.213-1.828 1.802 0 3.452.377 4.94 1.123 1.493.75 2.624 1.783 3.396 3.095v-3.546h9.664v.008ZM19.09 30.974c.921-1.18 1.38-2.878 1.38-5.091 0-2.251-.463-3.977-1.38-5.178-.921-1.201-2.245-1.8-3.975-1.8-1.658 0-2.953.62-3.894 1.857-.942 1.238-1.409 2.98-1.409 5.231 0 2.177.46 3.845 1.38 5.006.922 1.164 2.225 1.742 3.92 1.742 1.733.004 3.057-.587 3.978-1.767ZM82.42 12.102V39.78h-9.55v-3.714c-.85 1.35-1.924 2.382-3.231 3.095-1.308.714-2.808 1.07-4.502 1.07-3.496 0-6.092-.983-7.785-2.955-1.694-1.968-2.539-4.96-2.539-8.974v-16.2h9.829v16.426c0 2.701 1.139 4.05 3.424 4.05 1.323 0 2.41-.488 3.258-1.464.845-.975 1.272-2.25 1.272-3.824V12.102h9.825ZM112.398 13.171c1.802 1.165 3.211 2.825 4.224 4.981 1.01 2.156 1.517 4.661 1.517 7.51 0 2.85-.507 5.383-1.517 7.596-1.013 2.214-2.43 3.928-4.252 5.15-1.823 1.217-3.895 1.828-6.212 1.828-1.803 0-3.452-.378-4.941-1.124-1.492-.75-2.623-1.779-3.395-3.095v3.768h-9.664V.119h9.829v15.246c.808-1.201 1.939-2.157 3.395-2.87 1.453-.713 3.046-1.07 4.776-1.07 2.358 0 4.438.582 6.24 1.746Zm-5.355 17.747c.921-1.218 1.38-2.972 1.38-5.26 0-2.173-.451-3.845-1.352-5.005-.901-1.16-2.201-1.742-3.89-1.742-1.73 0-3.058.59-3.975 1.77-.922 1.181-1.38 2.878-1.38 5.092 0 2.25.458 3.976 1.38 5.177.921 1.201 2.229 1.8 3.918 1.8 1.694-.004 3.002-.61 3.919-1.832ZM34.79.094H45.22V28.85s-.056 2.537 2.435 2.599c2.49.061 3.536 0 3.536 0v7.928c-1.678.279-3.4.402-5.098.402-5.045 0-10.858-2.48-11.3-8.211-.005-5.022-.005-31.475-.005-31.475Z' />
      </g>
    </svg>
  );
}
