import { memo } from 'react';

import Image from 'next/image';

import { useRestaurant } from '@/hooks';
import { Container, Typography } from '@mui/material';

import styles from './styles.module.scss';

const OutletInfo = memo(function OutletInfo() {
  const { data } = useRestaurant();

  return (
    <>
      {!data?.config.hideLogo && data?.logoSmallUrl && (
        <div className={styles.logo}>
          <Image
            alt='outlet-logo'
            src={data.logoSmallUrl}
            fill
            priority={false}
            loading='lazy'
            quality={80}
            sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
          />
        </div>
      )}
      <Container className={styles.nameContainer}>
        <Typography
          variant='title_sm'
          align='center'
          color='var(--onSurfaceColors-highEmphasis)'
        >
          {data?.name}
        </Typography>
      </Container>
    </>
  );
});

export default OutletInfo;
