import { useTranslation } from 'next-export-i18n';

import { Layout } from '@/components';
import { QlubQR, QlubText } from '@/icons';
import { Box, Container, LinearProgress, Typography } from '@mui/material';

import styles from './styles.module.scss';

export default function PageLoader({ progress }: { progress: number }) {
  const { t } = useTranslation();

  return (
    <Layout
      footer={false}
      widthCapped
      sx={{
        container: styles.layoutContainer,
        innerContainer: styles.layoutInnerContainer,
      }}
    >
      <Container className={styles.loaderWrapper}>
        <Box gap='12px' display='flex' alignItems='center'>
          <QlubText color='var(--white)' height={50} />
          <QlubQR color='var(--white)' height={25} className={styles.spinner} />
        </Box>
        <Box width='60%'>
          <LinearProgress
            className={styles.loader}
            variant='determinate'
            value={progress}
            sx={{
              '& .MuiLinearProgress-barColorPrimary': {
                backgroundColor: 'var(--white)',
              },
            }}
          />
        </Box>
      </Container>
      <Container className={styles.end}>
        <Typography
          variant='body_sm'
          color='var(--onColorColors-mediumEmphasis)'
        >
          {t('preparing-your-bill-in-seconds')}
        </Typography>
      </Container>
    </Layout>
  );
}
