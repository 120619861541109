import { ChangeEvent, memo, useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { useTranslation } from 'next-export-i18n';
import { useSnackbar } from 'notistack';

import { usePaymentLink, useRestaurant } from '@/hooks';
import { sendBill } from '@/services';
import { useStore } from '@/store';
import { useQlubRouter } from '@clubpay/customer-common-module/src/hook/router';
import {
  getDiningSession,
  getSession,
} from '@clubpay/customer-common-module/src/service/session/dsi';
import {
  Button,
  Container,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';

const emailRegX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const SendReceipt = memo(function SendReceipt() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { query, url, lang } = useQlubRouter();
  const { data: restaurant } = useRestaurant();
  const dsi = getDiningSession(url)?.id || '';
  const id = getSession();
  const { ref, cc } = query as {
    ref: string;
    cc: string;
  };
  const userInfo = useStore(state => state.userInfo);
  const setUserInfo = useStore(state => state.setUserInfo);
  const [email, setEmail] = useState(userInfo?.email || '');

  const { refetch: sendEmail, isLoading } = useQuery({
    queryKey: ['payment-link-send-bill', dsi, id, email, ref, lang, cc],
    queryFn: () =>
      sendBill({
        dsi,
        id,
        email,
        reference: ref,
        lang,
        cc,
        tz: restaurant?.timezone?.timezone,
      })
        .then(() => {
          if (userInfo) setUserInfo({ ...userInfo, receiptSent: true });
          enqueueSnackbar(
            t('your-payment-receipt-has-been-sent-to-your-email'),
            {
              variant: 'success',
              autoHideDuration: 5000,
            },
          );
        })
        .catch(() =>
          enqueueSnackbar(t('your-payment-receipt-sending-has-been-failed'), {
            variant: 'error',
            autoHideDuration: 5000,
          }),
        ),
    enabled: false,
  });

  function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }

  useEffect(() => {
    if (
      restaurant?.config.paymentLinkCollectCustomerEmail &&
      !userInfo?.receiptSent &&
      emailRegX.test(userInfo?.email || '')
    ) {
      sendEmail();
    }
  }, []);

  return (
    <Container>
      <Stack alignItems='center' gap='16px' paddingY='16px'>
        <Typography variant='title_md'>{t('get-receipt-via-email')}</Typography>
        <OutlinedInput
          placeholder={t('your-email-address')}
          fullWidth
          type='email'
          value={email}
          onChange={handleOnChange}
        />
        <Button
          variant='outlined'
          fullWidth
          disabled={!emailRegX.test(email) || isLoading}
          onClick={() => sendEmail()}
        >
          {t('get-the-receipt')}
        </Button>
      </Stack>
    </Container>
  );
});

export default SendReceipt;
