import { memo, useCallback } from 'react';

import { useTranslation } from 'next-export-i18n';

import { Box, Chip, Container, Stack, Typography } from '@mui/material';

import styles from './styles.module.scss';

type Props = {
  status?: 'success' | 'failed';
  title?: string;
  currency?: string;
  amount?: string;
};

type ChipProps = { label: string; color: 'error' | 'success' };

const StatusDetails = memo(function StatusDetails({
  status = 'success',
  title,
  currency,
  amount,
}: Props) {
  const { t } = useTranslation();

  const chipProps: () => ChipProps = useCallback(() => {
    switch (status) {
      case 'failed':
        return {
          label: 'failed',
          color: 'error',
        };
      default:
        return {
          label: 'Paid',
          color: 'success',
        };
    }
  }, [status]);

  return (
    <Container data-status={status} className={styles.root}>
      {title && (
        <Stack direction='row' justifyContent='space-between'>
          <Typography variant='title_sm'>{title}</Typography>
          <Box display='flex' alignItems='baseline' gap='4px'>
            <Chip
              color={chipProps().color}
              label={t(chipProps().label)}
              size='small'
            />
          </Box>
        </Stack>
      )}
      {amount && currency && (
        <Stack direction='row' justifyContent='space-between'>
          <Typography variant='title_md'>{t('you-paid')}</Typography>
          <Box display='flex' alignItems='baseline' gap='4px'>
            <Typography variant='currency'>{currency}</Typography>
            <Typography variant='title_md'>{amount}</Typography>
          </Box>
        </Stack>
      )}
    </Container>
  );
});

export default StatusDetails;
