import { memo } from 'react';

import Link from 'next/link';

import { useTranslation } from 'next-export-i18n';

import { useRestaurant } from '@/hooks';
import { useQlubRouter } from '@clubpay/customer-common-module/src/hook/router';
import { interpolateT } from '@clubpay/customer-common-module/src/hook/translations';
import { Typography } from '@mui/material';

const TermsAndConditions = memo(function TermsAndConditions() {
  const { t } = useTranslation();
  const { url, lang } = useQlubRouter();
  const { data: restaurant } = useRestaurant();

  return (
    <Typography variant='caption' color='var(--onSurfaceColors-mediumEmphasis)'>
      {interpolateT(t('by-using-qlub-you-accept-our'), {
        terms: (
          <Link
            href={{
              pathname: '/qr/[cc]/terms',
              query: {
                cc: url.cc || restaurant?.country.code,
                ...(lang ? { lang } : {}),
              },
            }}
          >
            {t('terms-and-conditions')}
          </Link>
        ),
      })}
    </Typography>
  );
});

export default TermsAndConditions;
