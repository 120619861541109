import { memo } from 'react';

import { LanguageSelector, TermsAndConditions } from '@/components';
import { Container } from '@mui/material';

import styles from './styles.module.scss';

const Footer = memo(function Footer() {
  return (
    <Container className={styles.footer}>
      <LanguageSelector />
      <TermsAndConditions />
    </Container>
  );
});

export default Footer;
